import { Responsive, ResponsiveSection } from '../../../styles.interface';
import {config} from '@/product/config.json';

const HeaderNo2: ResponsiveSection = {
  TitleStyled: {
    md: {
      'max-width': '80%',
    },
  },
};

const PrivacyInfo: ResponsiveSection = {
  TermTitleParagraph: {
    xs: {
      'font-size': '14px',
      'font-weight': '400',
      'line-height': '20px',
    },
  },
  ExpandParagraph: {
    xs: {
      color: '#AAB6DF',
      'font-weight': '600',
      'font-size': '14px',
    },
  },
};

const Register: ResponsiveSection = {
  CreditCardBox: {
    xs: {
      background: config.STYLEGUIDE.colors.lighter,
      'border-color': '#152455',
    },
  },
  RegisterLabel: {
    xs: {
      color: config.STYLEGUIDE.others.textColor,
      'font-size': '13px',
    },
  },
  RegisterLink: {
    xs: {
      color: config.STYLEGUIDE.others.textColor,
      'font-size': '13px',
    },
  },
};

const Header: ResponsiveSection = {
  BackdropMenuLink: {
    xs: {
      color: '#ffffff',
    },
  },
  BackdropMenuSmallLink: {
    xs: {
      'font-size': '16px',
      'line-height': '22px',
    },
  },
};

const SubscriptionForm: ResponsiveSection = {
  LinkStyled: {
    xs: {
      color: config.STYLEGUIDE.colors.brand,
    },
  },
};

const MembershipSection: ResponsiveSection = {
  CoinsDataBox: {
    xs: {
      background: config.STYLEGUIDE.colors.lighter,
      border: `1px solid #152455`,
    },
  },
  ButtonStyled: {
    xs: {
      background: config.STYLEGUIDE.colors.error,
    },
  },
  ButtonStyledHover: {
    xs: {
      background: config.STYLEGUIDE.colors.info,
    },
  },
};

const OldPasswordForm: ResponsiveSection = {
  ButtonStyled: {
    xs: {
      background: config.STYLEGUIDE.colors.brand,
    },
  },
  ButtonStyledHover: {
    xs: {
      background: config.STYLEGUIDE.colors.infoHover,
    },
  },
};

const MessageForm: ResponsiveSection = {
  ButtonStyled: {
    xs: {
      background: config.STYLEGUIDE.colors.info,
    },
  },
  ButtonStyledHover: {
    xs: {
      background: config.STYLEGUIDE.colors.infoHover,
    },
  },
};

const Sections: Responsive = {
  HeaderNo2,
  Header,
  MessageForm,
  PrivacyInfo,
  Register,
  SubscriptionForm,
  MembershipSection,
  OldPasswordForm,
};

export default Sections;
